import React, { useEffect, useState } from "react"
import cx from "classnames"
import { graphql, useStaticQuery, Link } from "gatsby"
import loadable from "@loadable/component"

import { IMG } from "../const"
import { TITLES, SERVICES, BENEFITS } from "../data/fitness-sporttech"
import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import "../assets/styles/portfolio-chapter.scss"
import PortfolioImagesContainer from "../components/PortfolioImagesContainer"
import "../assets/styles/commons/_dash-before-text.scss"
import * as styles from "../assets/styles/fitness-sporttech.module.scss"
import Headline from "../components/headline"
import ServicesList from "../components/services-list"
import "../assets/styles/commons/_icon-badge.scss"

const TitleBlock = loadable(() => import("../components/title-block"))
const ContactForm = loadable(() => import("../components/contact-form"))

const FitnessSportTech = () => {
  const data = useStaticQuery(graphql`
    query {
      portfolioJson {
        name
        projects {
          title
          description
          metaTitle
          tagList
          country
          flagIconUrl
          imageLink
          mainBgImage
          mainPortfolioImage
          pageList
          externalLink
          slug
        }
      }
    }
  `)

  const [projectsList, setProjectsList] = useState([])

  useEffect(() => {
    let list = []

    data.portfolioJson.projects.map(
      item =>
        item.pageList?.length > 0 &&
        item.pageList.map(page => page === "sport_tech" && list.push(item))
    )

    setProjectsList(list)
  }, [data.portfolioJson.projects])

  return (
    <Layout
      pageTitle="Fitness & SporTech Development"
      metaDescription="Specializing in Fitness & SporTech app development, LaSoft offers scalable, user-friendly solutions for sports startups and fitness businesses."
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-fitness-sportech.jpg`}
    >
      {({
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main className={cx(styles.sportTechBlock, "common-kit")}>
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className="strongText">
                      We offer to meet the growing interest in
                    </p>
                    <h1 className="withIndent">
                      <strong>Fitness & sportech app</strong> development
                    </h1>
                    <div className="positionedText">
                      <p>
                        We build apps with functionality and features that
                        transform the fitness and sports industries
                      </p>
                    </div>
                    <div className="rightColumn">
                      <p>
                        Our team aims to provide efficient Full-Cycle Fitness
                        Application Development services to transform your
                        fitness or sports-related business into a digital
                        product.
                      </p>
                      <div>
                        <Link
                          to="#contact-form"
                          className="goldFatLink sportech-contact"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Headline>
              <div className="common__dash-before-text">
                <p>
                  We create multifunctional and scalable apps to support Fitness
                  & Sports Industry in creating intuitive interfaces, engaging
                  visuals, and user-friendly navigation with the option for
                  effective communication with clients.
                </p>
              </div>

              <section className={cx(styles.bannerBlock, "section")}>
                <TitleBlock options={TITLES.banner} />

                <div className={styles.textTwoColumns}>
                  <p>
                    For your sports startup, fitness club, golf club, gym, or
                    yoga studio
                  </p>
                  <p>
                    For customers who want to make a workout app and monetize it
                  </p>
                </div>

                <div className={styles.bannerWrapper}>
                  <picture>
                    <source
                      media="(max-width: 415px)"
                      srcSet={`${IMG.SPORTECH}/sport_tech-bn-mobile.avif, ${IMG.SPORTECH}/sport_tech-bn-mobile@2x.avif 2x, ${IMG.SPORTECH}/sport_tech-bn-mobile@3x.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(max-width: 415px)"
                      srcSet={`${IMG.SPORTECH}/sport_tech-bn-mobile.webp, ${IMG.SPORTECH}/sport_tech-bn-mobile@2x.webp 2x, ${IMG.SPORTECH}/sport_tech-bn-mobile@3x.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(max-width: 415px)"
                      srcSet={`${IMG.SPORTECH}/sport_tech-bn-mobile.jpg, ${IMG.SPORTECH}/sport_tech-bn-mobile@2x.jpg 2x, ${IMG.SPORTECH}/sport_tech-bn-mobile@3x.jpg 3x`}
                    />

                    <source
                      media="(min-width: 415px) and (max-width: 651px)"
                      srcSet={`${IMG.SPORTECH}/sport_tech-bn-tb.avif, ${IMG.SPORTECH}/sport_tech-bn-tb@2x.avif 2x, ${IMG.SPORTECH}/sport_tech-bn-tb@3x.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 415px) and (max-width: 651px)"
                      srcSet={`${IMG.SPORTECH}/sport_tech-bn-tb.webp, ${IMG.SPORTECH}/sport_tech-bn-tb@2x.webp 2x, ${IMG.SPORTECH}/sport_tech-bn-tb@3x.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 415px) and (max-width: 651px)"
                      srcSet={`${IMG.SPORTECH}/sport_tech-bn-tb.jpg, ${IMG.SPORTECH}/sport_tech-bn-tb@2x.jpg 2x, ${IMG.SPORTECH}/sport_tech-bn-tb@3x.jpg 3x`}
                    />

                    <source
                      media="(min-width: 651px)"
                      srcSet={`${IMG.SPORTECH}/sport_tech-bn-desktop.avif, ${IMG.SPORTECH}/sport_tech-bn-desktop@2x.avif 2x, ${IMG.SPORTECH}/sport_tech-bn-desktop@3x.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 651px)"
                      srcSet={`${IMG.SPORTECH}/sport_tech-bn-desktop.webp, ${IMG.SPORTECH}/sport_tech-bn-desktop@2x.webp 2x, ${IMG.SPORTECH}/sport_tech-bn-desktop@3x.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 651px)"
                      srcSet={`${IMG.SPORTECH}/sport_tech-bn-desktop.jpg, ${IMG.SPORTECH}/sport_tech-bn-desktop@2x.jpg 2x, ${IMG.SPORTECH}/sport_tech-bn-desktop@3x.jpg 3x`}
                    />

                    <img
                      src={`${IMG.SPORTECH}/sport_tech-bn-desktop.jpg`}
                      alt="Banner"
                      width="1210"
                      height="560"
                      loading="lazy"
                    />
                  </picture>
                  <div>
                    <h3>Discover and estimate my SporTech project</h3>
                    <p>
                      We encourage you to start a new project with us to become
                      an innovative gamechanger in the market
                    </p>
                    <Link
                      to="#contact-form"
                      className="goldFatLink  sportech-contact-two"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
                <div className={styles.mobileBtnWrapper}>
                  <Link
                    to="#contact-form"
                    className="goldFatLink  sportech-contact-two"
                  >
                    Contact us
                  </Link>
                </div>
              </section>

              <section className="section">
                <TitleBlock options={TITLES.options} />
                <ServicesList services={SERVICES.items} />
              </section>

              <section className="section">
                <TitleBlock options={TITLES.benefits} />
                <div className={styles.cardsWrapper}>
                  <div>
                    {BENEFITS.map(item => (
                      <article
                        key={item.id}
                        className={cx(
                          styles.benefitCard,
                          `icon-badge ${item.icon}`
                        )}
                      >
                        <h3 className={styles.title}>{item.title}</h3>
                        <p>{item.text}</p>
                      </article>
                    ))}
                  </div>
                </div>
              </section>
              <div>
                <div className={styles.casesTitle}>
                  <TitleBlock options={TITLES.case_studies} />
                </div>
                <div className="portfolioChapter__list">
                  {projectsList.length > 0 &&
                    projectsList.map(item => (
                      <section
                        className="portfolioChapter__singleProjectSummary"
                        key={item.slug || item.title}
                      >
                        <div className="portfolioChapter__singleProjectSummary__text">
                          <div className="portfolioChapter__singleProjectSummary__text__description">
                            <h2>
                              {item.externalLink ? (
                                <a
                                  href={item.externalLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.title}
                                </a>
                              ) : item.slug ? (
                                <Link to={`/${item.slug}/`}>{item.title}</Link>
                              ) : (
                                item.title
                              )}
                            </h2>
                            <ul className="tags">
                              <li className="flag">
                                <img
                                  src={`${IMG.INDEX}/${item.flagIconUrl}`}
                                  width="22"
                                  height="16"
                                  alt={`${item.country} flag`}
                                  title={`${item.country} flag`}
                                  loading="lazy"
                                />
                              </li>
                              {item.tagList.map(tag => (
                                <li key={`tag_${tag.replace(/\s/g, "")}`}>
                                  {tag}
                                </li>
                              ))}
                            </ul>
                            <div
                              className="portfolioChapter__singleProjectSummary__visual mobileOnly"
                              data-title={item.title}
                            >
                              <PortfolioImagesContainer item={item} />
                            </div>
                            <div className="info">
                              <p>{item.description}</p>
                            </div>
                            {item.externalLink && (
                              <a
                                href={item.externalLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="portfolioChapter__seeMore"
                              >
                                Discover project
                              </a>
                            )}
                            {item.slug && (
                              <Link
                                to={`/${item.slug}/`}
                                className="portfolioChapter__seeMore"
                              >
                                Discover project
                              </Link>
                            )}
                          </div>
                        </div>
                        <div
                          className="portfolioChapter__singleProjectSummary__visual"
                          data-title={item.title}
                        >
                          <PortfolioImagesContainer item={item} />
                        </div>
                      </section>
                    ))}
                </div>
              </div>
            </article>
            <section className="footerForm common-kit">
              <div className="section" id="contact-form">
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default FitnessSportTech
